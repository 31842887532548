import * as React from 'react';
const SvgAccount = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={28}
    fill="none"
    {...props}
  >
    <g fill="#000" clipPath="url(#account_svg__a)">
      <path d="M12.386 15.069h.198c1.779-.03 3.223-.621 4.288-1.762 2.342-2.506 1.954-6.802 1.908-7.213-.152-3.076-1.687-4.549-2.95-5.242-.943-.513-2.045-.794-3.27-.816h-.106c-.676 0-2 .101-3.27.794C7.9 1.516 6.35 2.99 6.198 6.094c-.046.411-.434 4.707 1.908 7.213 1.057 1.14 2.502 1.733 4.28 1.762m-4.57-8.83c0-.015.008-.037.008-.044.198-4.144 3.3-4.585 4.623-4.585h.076c1.642.036 4.433.672 4.623 4.585 0 .014 0 .036.007.043.008.044.434 3.971-1.505 6.036-.768.824-1.787 1.228-3.133 1.235h-.06c-1.339-.014-2.365-.419-3.126-1.235-1.93-2.057-1.52-6-1.513-6.036" />
      <path d="M24.985 22.195v-.015c0-.043-.008-.093-.008-.144-.038-1.14-.114-3.82-2.752-4.671a.188.188 0 0 0-.061-.015c-2.745-.664-5.026-2.166-5.049-2.18a.852.852 0 0 0-1.14.187.757.757 0 0 0 .197 1.083c.107.073 2.525 1.668 5.55 2.412 1.415.476 1.575 1.92 1.62 3.235 0 .05 0 .1.008.144.008.52-.03 1.321-.13 1.783-.988.535-4.85 2.369-10.72 2.369-5.855 0-9.74-1.841-10.728-2.376-.1-.462-.137-1.263-.13-1.783 0-.044.008-.094.008-.145.046-1.314.198-2.758 1.62-3.234 3.026-.737 5.451-2.347 5.55-2.412a.746.746 0 0 0 .198-1.083.839.839 0 0 0-1.14-.188c-.024.015-2.297 1.517-5.05 2.18a.242.242 0 0 1-.06.015C.129 18.217.046 20.895.015 22.03c0 .05 0 .101-.007.144v.015C0 22.49-.008 24.028.319 24.8c.061.152.168.275.32.361.182.116 4.554 2.759 11.876 2.759 7.322 0 11.694-2.65 11.877-2.759a.831.831 0 0 0 .32-.36c.28-.759.28-2.304.273-2.607" />
    </g>
    <defs>
      <clipPath id="account_svg__a">
        <path fill="#fff" d="M0 0h25v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAccount;
