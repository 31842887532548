import * as React from 'react';
const SvgEmail = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#9CB83B"
      d="M25.899 0H2.1C.941 0 0 .94 0 2.101V25.9C0 27.059.94 28 2.101 28H25.9c1.16 0 2.101-.94 2.101-2.101V2.1C28 .941 27.06 0 25.899 0M20.49 7.984l-6.494 6.494-6.493-6.494zM5.62 18.486a.814.814 0 0 1-.096-.384V8.805a.82.82 0 0 1 .096-.385l5.033 5.033zm1.885.436 4.309-4.309 2.185 2.185 2.187-2.187 4.311 4.31zm14.973-.82a.82.82 0 0 1-.096.384l-5.035-5.035 5.034-5.034a.813.813 0 0 1 .097.388z"
    />
  </svg>
);
export default SvgEmail;
