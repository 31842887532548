import * as React from 'react';
const SvgPrevArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#prevArrow_svg__a)">
      <path
        fill="#4F6834"
        d="M16 0c-2.208 0-4.286.417-6.234 1.25a16.056 16.056 0 0 0-5.094 3.422A16.056 16.056 0 0 0 1.25 9.766C.417 11.714 0 13.792 0 16s.417 4.286 1.25 6.234a16.055 16.055 0 0 0 3.422 5.094 16.056 16.056 0 0 0 5.094 3.422C11.714 31.583 13.792 32 16 32s4.286-.417 6.234-1.25a16.055 16.055 0 0 0 5.094-3.422 16.055 16.055 0 0 0 3.422-5.094C31.583 20.286 32 18.208 32 16s-.417-4.286-1.25-6.234a16.056 16.056 0 0 0-3.422-5.094 16.055 16.055 0 0 0-5.094-3.422C20.286.417 18.208 0 16 0m0 30c-1.937 0-3.755-.365-5.453-1.094a14.097 14.097 0 0 1-4.453-3 14.098 14.098 0 0 1-3-4.453C2.364 19.755 2 17.938 2 16c0-1.937.365-3.755 1.094-5.453a14.1 14.1 0 0 1 3-4.453 14.1 14.1 0 0 1 4.453-3C12.245 2.364 14.062 2 16 2c1.938 0 3.755.365 5.453 1.094 1.698.729 3.182 1.729 4.453 3a14.097 14.097 0 0 1 3 4.453C29.636 12.245 30 14.062 30 16c0 1.938-.365 3.755-1.094 5.453a14.096 14.096 0 0 1-3 4.453 14.096 14.096 0 0 1-4.453 3C19.755 29.636 17.938 30 16 30m7-15H12.406l4.125-4.125a.907.907 0 0 0 .313-.703.907.907 0 0 0-.313-.703.907.907 0 0 0-.703-.313.907.907 0 0 0-.703.313l-5.656 5.656a.93.93 0 0 0-.25.422.837.837 0 0 0 0 .453.837.837 0 0 0 0 .453.93.93 0 0 0 .25.422l5.656 5.656a.907.907 0 0 0 .703.313.906.906 0 0 0 .703-.313.906.906 0 0 0 .313-.703.907.907 0 0 0-.313-.703L12.406 17H23c.27 0 .505-.099.703-.297A.961.961 0 0 0 24 16a.961.961 0 0 0-.297-.703A.961.961 0 0 0 23 15"
      />
    </g>
    <defs>
      <clipPath id="prevArrow_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPrevArrow;
