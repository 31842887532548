import * as React from 'react';
const SvgFace = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#9CB83B"
      d="M26.454 0H1.544C.691 0 0 .692 0 1.546v24.91C0 27.309.692 28 1.546 28h13.411V17.172H11.32v-4.238h3.637v-3.12c0-3.617 2.208-5.585 5.435-5.585 1.545 0 2.873.115 3.26.166v3.78h-2.225c-1.755 0-2.095.835-2.095 2.06v2.699h4.197l-.547 4.238h-3.65V28h7.122c.854 0 1.546-.692 1.546-1.545V1.544C28 .69 27.308 0 26.454 0"
    />
  </svg>
);
export default SvgFace;
