import * as React from 'react';
const SvgSearch = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={112}
    height={112}
    fill="none"
    {...props}
  >
    <path
      fill="#758939"
      d="m111.199 93.656-1.398-1.398a1.64 1.64 0 0 0-2.32 2.32l1.014 1.015-12.902 12.902-6.488-6.488a3.824 3.824 0 0 1-1.127-2.723c0-1.028.4-1.995 1.128-2.722l7.456-7.457a3.825 3.825 0 0 1 2.722-1.127c1.029 0 1.996.4 2.723 1.128l.397.396a1.64 1.64 0 0 0 2.32-2.32l-.396-.397a7.087 7.087 0 0 0-5.044-2.089c-.745 0-1.471.115-2.16.333l-6.116-6.115c5.634-7.977 8.822-17.44 9.113-27.363.375-12.756-4.055-25.035-12.474-34.576a1.64 1.64 0 0 0-2.46 2.171c16.292 18.463 15.4 46.58-2.03 64.01-8.837 8.838-20.587 13.705-33.085 13.705-12.499 0-24.249-4.867-33.086-13.704C8.148 74.32 3.28 62.57 3.28 50.072c0-12.499 4.867-24.249 13.705-33.086A46.644 46.644 0 0 1 48.21 3.318c11.718-.466 23.092 3.434 32.017 10.974a1.64 1.64 0 1 0 2.117-2.507C72.79 3.715 60.62-.455 48.08.039a49.918 49.918 0 0 0-33.415 14.626C5.208 24.123 0 36.697 0 50.072 0 63.446 5.208 76.02 14.665 85.477c9.458 9.457 22.032 14.666 35.407 14.666 10.478 0 20.464-3.198 28.846-9.13l6.111 6.11a7.15 7.15 0 0 0-.333 2.161c0 1.906.742 3.697 2.09 5.044l6.87 6.871a2.73 2.73 0 0 0 1.937.801c.701 0 1.403-.267 1.937-.801l13.669-13.669a2.742 2.742 0 0 0 0-3.874m-25.722-8.179a50.655 50.655 0 0 0 3.53-3.923l5.233 5.232-7.454 7.454-5.23-5.23a50.919 50.919 0 0 0 3.921-3.533"
    />
    <path
      fill="#758939"
      d="M13.877 52.029a1.64 1.64 0 0 0-1.47 1.794 37.92 37.92 0 0 0 10.9 23.012c7.379 7.379 17.071 11.068 26.764 11.068 9.692 0 19.385-3.69 26.764-11.068 14.757-14.757 14.757-38.77 0-53.527-7.15-7.15-16.654-11.086-26.764-11.086s-19.615 3.937-26.764 11.086a37.909 37.909 0 0 0-10.913 23.136 1.64 1.64 0 0 0 3.267.313 34.617 34.617 0 0 1 9.967-21.129c6.529-6.53 15.21-10.125 24.443-10.125 9.234 0 17.915 3.596 24.443 10.125 13.479 13.478 13.479 35.409 0 48.887-13.477 13.478-35.408 13.478-48.886 0A34.627 34.627 0 0 1 15.672 53.5a1.641 1.641 0 0 0-1.795-1.471"
    />
    <path
      fill="#9CB83B"
      d="M28.671 54.487a18.51 18.51 0 0 0 16.49 5.107 3.49 3.49 0 0 0 .393-.1 11.429 11.429 0 0 1 2.755 7.46v8.458a1.64 1.64 0 1 0 3.28 0v-8.458c0-2.757.975-5.38 2.755-7.46.129.04.26.075.394.1a18.515 18.515 0 0 0 16.489-5.107 18.524 18.524 0 0 0 5.107-16.489 3.61 3.61 0 0 0-2.872-2.872 18.523 18.523 0 0 0-16.49 5.107 18.523 18.523 0 0 0-5.106 16.489c.03.163.073.321.125.476a14.778 14.778 0 0 0-2.042 2.981 14.767 14.767 0 0 0-2.042-2.98c.052-.156.095-.314.125-.477a18.522 18.522 0 0 0-5.107-16.489 18.521 18.521 0 0 0-16.489-5.107 3.61 3.61 0 0 0-2.872 2.872 18.524 18.524 0 0 0 5.107 16.489m30.622-11.934a15.231 15.231 0 0 1 13.57-4.2.312.312 0 0 1 .245.244 15.243 15.243 0 0 1-4.2 13.57 15.236 15.236 0 0 1-11.803 4.423l6.17-6.423a1.641 1.641 0 0 0-2.365-2.274l-6.05 6.297a15.233 15.233 0 0 1 4.433-11.637m-32.502-3.955a.308.308 0 0 1 .245-.246 15.232 15.232 0 0 1 13.57 4.2 15.234 15.234 0 0 1 4.433 11.638l-6.05-6.297a1.64 1.64 0 1 0-2.366 2.274l6.171 6.423a15.234 15.234 0 0 1-11.802-4.423 15.242 15.242 0 0 1-4.201-13.57"
    />
  </svg>
);
export default SvgSearch;
