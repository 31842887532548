import * as React from 'react';
const SvgDouble = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#4E3628"
      d="M15 27.5V16H8c0-5.5 1-7.5 5-10.5l-3-5C1 5.5 0 13 0 16v11.5zM33 27.5V16h-7c0-5.5 1-7.5 5-10.5l-3-5C19 5.5 18 13 18 16v11.5z"
    />
  </svg>
);
export default SvgDouble;
