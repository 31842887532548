import * as React from 'react';
const SvgArrowRight = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#B3BE72"
      d="M10.456 5.242a.487.487 0 0 0 .044-.22.965.965 0 0 0-.044-.278.231.231 0 0 1-.059-.088.382.382 0 0 0-.059-.103L5.949.19a.606.606 0 0 0-.881 0 .603.603 0 0 0 0 .879l3.318 3.309H1.117a.606.606 0 0 0-.448.168.603.603 0 0 0-.169.447c0 .195.056.349.169.46a.606.606 0 0 0 .448.17h7.268L5.067 8.93a.603.603 0 0 0 0 .879.838.838 0 0 0 .227.139.58.58 0 0 0 .213.051.58.58 0 0 0 .213-.051.838.838 0 0 0 .228-.14l4.39-4.377c.03-.03.057-.059.081-.088a.155.155 0 0 0 .037-.102"
    />
  </svg>
);
export default SvgArrowRight;
