import * as React from 'react';
const SvgGlobal = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={57}
    fill="none"
    {...props}
  >
    <path
      fill="#9CB83B"
      d="M1.804 26.538a1.37 1.37 0 0 0 1.362 1.49h1.722c1.905 0 3.58-1.365 3.983-3.245.237-1.105.572-2.191.997-3.24L12.325 24a1.364 1.364 0 0 0 2.235-.459 14.541 14.541 0 0 1 8.075-8.077c.427-.17.737-.542.83-.993a1.364 1.364 0 0 0-.372-1.242l-2.455-2.455a19.466 19.466 0 0 1 3.24-.998c1.88-.403 3.244-2.078 3.244-3.983V4.072a1.371 1.371 0 0 0-1.49-1.363 26.124 26.124 0 0 0-8.275 2.138.82.82 0 1 0 .664 1.5 24.48 24.48 0 0 1 7.46-1.975v1.421c0 1.155-.8 2.134-1.947 2.38-1.48.317-2.93.795-4.308 1.423a1.095 1.095 0 0 0-.318 1.768l2.735 2.736a16.199 16.199 0 0 0-8.448 8.45l-2.737-2.737a1.09 1.09 0 0 0-1.768.318 21.129 21.129 0 0 0-1.423 4.308c-.246 1.147-1.224 1.948-2.38 1.948h-1.42C4.223 18.94 8.44 12.114 14.787 8.078a.82.82 0 1 0-.88-1.384c-6.7 4.259-11.36 11.491-12.104 19.844M4.779 10.463a4.784 4.784 0 0 0 4.779-4.778 4.784 4.784 0 0 0-4.78-4.78A4.784 4.784 0 0 0 0 5.686a4.784 4.784 0 0 0 4.779 4.778m0-7.917a3.142 3.142 0 0 1 3.138 3.139A3.142 3.142 0 0 1 4.78 8.823 3.142 3.142 0 0 1 1.64 5.685a3.142 3.142 0 0 1 3.138-3.139M32.123 9.777c1.104.237 2.19.571 3.239.997l-2.457 2.456c-.326.326-.465.79-.372 1.242.093.451.403.823.831.993a14.541 14.541 0 0 1 8.077 8.075c.171.428.542.738.993.83.452.094.916-.046 1.242-.372l2.456-2.455c.426 1.049.76 2.135.997 3.24.403 1.88 2.079 3.244 3.984 3.244h1.72a1.371 1.371 0 0 0 1.363-1.489A26.31 26.31 0 0 0 30.367 2.71a1.37 1.37 0 0 0-1.489 1.363v1.721c0 1.905 1.365 3.58 3.245 3.984m-1.604-5.405a24.607 24.607 0 0 1 22.015 22.015h-1.421c-1.156 0-2.134-.801-2.38-1.948a21.091 21.091 0 0 0-1.423-4.308 1.09 1.09 0 0 0-1.768-.318l-2.736 2.735a16.2 16.2 0 0 0-8.45-8.447l2.736-2.737a1.096 1.096 0 0 0-.318-1.768 21.116 21.116 0 0 0-4.308-1.423c-1.146-.246-1.947-1.225-1.947-2.38z"
    />
    <path
      fill="#9CB83B"
      d="M51.221 10.463A4.784 4.784 0 0 0 56 5.685a4.784 4.784 0 0 0-4.779-4.78 4.784 4.784 0 0 0-4.779 4.78 4.784 4.784 0 0 0 4.78 4.778m0-7.917a3.142 3.142 0 0 1 3.138 3.139 3.142 3.142 0 0 1-3.138 3.138 3.142 3.142 0 0 1-3.138-3.138 3.142 3.142 0 0 1 3.138-3.139M54.196 31.273a1.37 1.37 0 0 0-1.362-1.489h-1.722c-1.905 0-3.58 1.364-3.983 3.244a19.485 19.485 0 0 1-.997 3.24l-2.457-2.457a1.364 1.364 0 0 0-2.235.459 14.54 14.54 0 0 1-8.075 8.077c-.427.17-.737.542-.83.993-.093.451.046.916.372 1.242l2.455 2.456c-1.048.425-2.135.76-3.24.997-1.88.403-3.244 2.078-3.244 3.984v1.72a1.371 1.371 0 0 0 1.49 1.363 26.31 26.31 0 0 0 23.828-23.83M30.52 53.439v-1.42c0-1.156.8-2.134 1.948-2.38a21.11 21.11 0 0 0 4.307-1.424 1.095 1.095 0 0 0 .318-1.768l-2.735-2.736a16.199 16.199 0 0 0 8.448-8.45l2.737 2.737a1.095 1.095 0 0 0 1.768-.319 21.126 21.126 0 0 0 1.423-4.307c.246-1.147 1.224-1.947 2.38-1.947h1.42A24.607 24.607 0 0 1 30.52 53.439"
    />
    <path
      fill="#9CB83B"
      d="M51.221 47.348a4.784 4.784 0 0 0-4.779 4.779 4.784 4.784 0 0 0 4.78 4.779A4.784 4.784 0 0 0 56 52.126a4.784 4.784 0 0 0-4.779-4.778m0 7.917a3.142 3.142 0 0 1-3.138-3.138 3.142 3.142 0 0 1 3.138-3.139 3.142 3.142 0 0 1 3.138 3.139 3.142 3.142 0 0 1-3.138 3.138M23.878 48.035a19.468 19.468 0 0 1-3.24-.998l2.457-2.456c.326-.326.465-.79.372-1.242a1.361 1.361 0 0 0-.831-.993 14.571 14.571 0 0 1-5.29-3.651.82.82 0 1 0-1.208 1.11 16.234 16.234 0 0 0 5.507 3.906l-2.737 2.736a1.09 1.09 0 0 0 .319 1.768 21.115 21.115 0 0 0 4.307 1.423c1.146.246 1.947 1.225 1.947 2.38v1.421A24.607 24.607 0 0 1 3.466 31.425h1.421c1.156 0 2.134.8 2.38 1.947A21.09 21.09 0 0 0 8.69 37.68a1.09 1.09 0 0 0 1.768.318l2.736-2.735c.182.423.383.84.6 1.244a.82.82 0 1 0 1.446-.775 14.48 14.48 0 0 1-.681-1.461 1.362 1.362 0 0 0-.993-.83 1.362 1.362 0 0 0-1.242.372l-2.456 2.455a19.457 19.457 0 0 1-.997-3.24c-.403-1.88-2.079-3.244-3.984-3.244h-1.72a1.372 1.372 0 0 0-1.363 1.489 26.31 26.31 0 0 0 23.829 23.829 1.37 1.37 0 0 0 1.489-1.363v-1.721c0-1.905-1.365-3.58-3.244-3.983"
    />
    <path
      fill="#9CB83B"
      d="M4.779 47.348A4.784 4.784 0 0 0 0 52.127a4.784 4.784 0 0 0 4.779 4.779 4.784 4.784 0 0 0 4.779-4.78 4.784 4.784 0 0 0-4.78-4.778m0 7.917a3.142 3.142 0 0 1-3.138-3.138 3.142 3.142 0 0 1 3.138-3.139 3.142 3.142 0 0 1 3.138 3.139 3.142 3.142 0 0 1-3.138 3.138M32.6 35.864a.82.82 0 0 0 1.152.074l3.861-3.354a7.107 7.107 0 0 0 2.444-5.958c-.268-3.531-3.137-6.361-6.674-6.582-2-.125-3.943.583-5.383 1.952a7.152 7.152 0 0 0-5.383-1.952c-3.537.22-6.406 3.05-6.674 6.582a7.107 7.107 0 0 0 2.443 5.958l8.447 7.336c.333.289.75.433 1.167.433.417 0 .834-.144 1.167-.433l1.618-1.406c.34-.294.408-.812.124-1.16a.82.82 0 0 0-1.174-.1l-1.644 1.427a.14.14 0 0 1-.182 0l-8.447-7.335a5.481 5.481 0 0 1-1.883-4.596c.206-2.72 2.416-4.899 5.14-5.07a5.516 5.516 0 0 1 4.336 1.689c.246.257.59.405.945.405.354 0 .699-.148.945-.405a5.513 5.513 0 0 1 4.336-1.688c2.724.17 4.934 2.35 5.14 5.07a5.48 5.48 0 0 1-1.883 4.595l-3.862 3.353a.82.82 0 0 0-.076 1.165"
    />
  </svg>
);
export default SvgGlobal;
